.globiance-widget-XF99AS {
  .primary-heading {
    font-size: 35px;
    font-weight: 700;
  }

  a {
    transition: none;
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: inherit;
    }
  }

  @media screen and (max-width:1200px) {
    #widget {
      @include fontSize($body-font-size - 2px);

      h1 {
        @include fontSize($h1-font-size - 2px);
      }

      h2 {
        @include fontSize($h2-font-size - 2px);
      }

      h3 {
        @include fontSize($h3-font-size - 2px);
      }

      h4 {
        @include fontSize($h4-font-size - 2px);
      }

      h5 {
        @include fontSize($h5-font-size - 2px);
      }

      h6 {
        @include fontSize($h6-font-size - 2px);
      }
    }
  }

  @media only screen and (max-width: 1600px) {
    .primary-heading {
      font-size: 28px;
    }
  }

  @media only screen and (max-width: 600px) {
    .primary-heading {
      font-size: 22px;
    }

  }

  .secondary-text {
    font-family: $futura-md-bt;
    font-size: 12px;
  }
}