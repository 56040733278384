@import "bootstrap/scss/bootstrap.scss";

@import "abstracts/functions";
@import "abstracts/mixins";

@import "variables/variables";

@import "base/fonts";

@import "components/formST";

@import "base/animations";
@import "base/base";
@import "base/typographic";
@import "base/utilities";

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.react-datepicker-wrapper{
  margin-top: 16px !important;
  width: 100%;
}