.mainContainer {
  width: 100%;
  overflow: hidden;
  margin: 14px 0px;
  max-width: 1038px;
  border-radius: 10px;
  border: 1px solid var(--glob--card-border-color);

  >h1 {
    color: var(--glob--text-color-secondary);
    text-align: center;
    width: 100%;
    font-size: 26px;
    padding: 70px 0px;
  }
}

.containerHeader {
  display: flex;
  align-items: center;
  background-color: var(--glob--card-bg-color);

  @media screen and (min-width: 768px) {
    padding: 20px 37px;
    justify-content: space-between;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    padding: 10px;

    >div {
      width: 100%;
      margin: 5px 0px;
    }
  }
}

.textSection {

  h3,
  h1 {
    font-weight: 400;
    text-align: left;
    font-family: Roboto;
  }

  h3 {
    font-size: 28px;
    margin-bottom: 4.5px;
    color: var(--glob--text-color-secondary);
  }

  h1 {
    font-size: 52px;
    margin-bottom: 0px;
    color: var(--glob--text-color);

    @media screen and (max-width: 767px) {
      text-align: center !important;
    }
  }
}

.logoSection {
  display: flex;
  overflow: hidden;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  >img {
    height: 120px;
    width: 120px;
    object-fit: contain;
  }

  @media screen and (max-width: 767px) {
    margin: 10px 0px !important;
  }
}

.mainSection {
  width: 100%;
  display: flex;
  background-color: var(--glob--card-bg-color);

  hr {
    border-top: 1px solid var(--glob--card-border-color);
    width: 94%;
    margin: 0px;
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    height: 428px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;

    >div {
      margin: 10px 0px;
    }
  }
}

.lftSection,
.rtSection {
  display: flex;
  height: inherit;
  flex-direction: column;
  justify-content: space-between;

  >div {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    padding: 25px 30px;
    border: 1px solid var(--glob--card-border-color);
  }

  @media screen and (max-width: 767px) {
    padding: 25px 10px;
    margin: 5px 0px;
  }
}

.lftSection {
  width: 58.7%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.lftSection__top {

  >p {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 400;
    line-height: 25.78px;
    color: var(--glob--text-color-secondary);
  }

  @media screen and (max-width: 767px) {
    min-height: 268px;
  }
}

.lftSection__bottom {
  display: flex;
  flex-direction: column;

  >div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    >p {
      font-family: Roboto;
      font-size: 22px;
      font-weight: 400;
      line-height: 25.78px;
      text-align: left;
      margin-bottom: 0px;
      color: var(--glob--text-color);
    }
  }

  >div:first-child {
    margin-bottom: 10px;
  }
}

.itemsList {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  >div:first-child {
    width: 100%;
  }

  p,
  b {
    font-family: Roboto;
    line-height: 30.47px;
    text-align: left;
  }

  p {
    font-size: 22px;
    font-weight: 400;
    color: var(--glob--text-color-secondary);
  }

  b {
    font-size: 26px;
    font-weight: 700;
    color: var(--glob--text-color);
  }
}

.rtSection {
  width: 41.3%;

  @media screen and (max-width: 767px) {
    width: 100%;
  }
}

.emailSection {
  >p {
    display: flex;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    font-family: Roboto;
    align-items: center;
    line-height: 21.09px;
    color: var(--glob--text-color-secondary);

    >svg {
      width: 16.8px;
      height: 16.8px;
      margin-left: 11px;
      fill: var(--glob--text-color-secondary);
    }
  }

  >input {
    width: 100%;
    padding: 5px;
    height: 40px;
    border-radius: 6px;
    color: var(--glob--text-color);
    background-color: var(--glob--bg-color);
    border: 1px solid var(--glob--card-border-color);

    &:focus-visible{
      outline: none !important;
    }
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 52px;
  }
}

.planStartSection {
  margin-top: 1rem;

  >p {
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    font-family: Roboto;
    line-height: 21.09px;
    color: var(--glob--text-color-secondary);
  }

  .radioButtons {
    display: flex;
    align-items: center;

    >label {
      margin-right: 20px;
      display: flex;
      align-items: center;
      color: var(--glob--text-color);

      >input {
        margin-right: 10px;
      }
    }
  }
}

.react-datepicker-wrapper {
  width: 100% !important;
  padding: 5px !important;
  height: 40px !important;
  border-radius: 6px !important;
  color: var(--glob--text-color) !important;
  background-color: var(--glob--bg-color) !important;
  border: 1px solid var(--glob--card-border-color) !important;

  &:focus-visible{
    outline: none !important;
  }
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: center;

  button:disabled {
    opacity: 0.5;
  }

  >button {
    width: 100%;
    height: 53px;
    border: none;
    display: flex;
    color: #fff;
    border-radius: 6px;
    padding: 14px, 6px;
    align-items: center;
    justify-content: center;
    background-color: #5C7FDA;

    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 23.44px;

    >span {

      >svg {
        width: 25px;
        height: 25px;
        margin: 0px 3px;
      }
    }

    >div{
      width: auto !important;
      margin: 0px 10px !important;
    }

    @media screen and (max-width: 767px) {
      width: calc(100% - 52px);
    }
  }

  @media screen and (min-width: 793px) {
    >button {
      >span {
        margin-left: 30px;
      }
    }
  }
}

.dynamicForm {
  @media screen and (min-width: 767px) {
    overflow: auto;
    height: 83%;
  }

  >p {
    display: flex;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    font-family: Roboto;
    align-items: center;
    line-height: 21.09px;
    color: var(--glob--text-color-secondary);

    >svg {
      width: 16.8px;
      height: 16.8px;
      margin-left: 11px;
      fill: var(--glob--text-color-secondary);
    }
  }
}