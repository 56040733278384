.mainContainer {
  display: flex;
  min-height: 100vh;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  
  @media screen and (min-width: 768px) {
    max-width: 1089px;
  }
  
  @media screen and (max-width: 767px) {
    padding: 70px 10px;
    width: 100vw;
  }
}