// Container
.settings {
  gap: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

// Language Dropdown
.languageSelect {
  width: 62px;
  height: 30px;
  padding-left: 5px;
  font-size: 16px;
  appearance: none;
  border-radius: 5px;
  background-size: 12px; 
  -moz-appearance: none;
  background: transparent;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  color: var(--glob--text-color);
  background-position: right 10px center; 
  border: 1px solid var(--glob--card-border-color);
  background-color: var(--glob--card-bg-color);
  background-image: url('../../assets/svg/ddArrow.svg');
}

.languageSelectWrapper {
  position: relative;
  display: inline-block;
}

// Theme Toggle
.themeToggle {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 30px;
}

.themeSwitch {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-toggle-bg);
  transition: 0.4s;
  border-radius: 20px;
}

.slider::before {
  position: absolute;
  content: '';
  height: 28px;
  width: 28px;
  left: 1px;
  bottom: 1px;
  background-color: var(--theme-toggle-circle);
  transition: 0.4s;
  border-radius: 50%;
}

.themeSwitch:checked + .slider::before {
  transform: translateX(25px);
}

// Icon styles
.icon {
  position: absolute;
  top: 50%;
  left: 27%;
  transform: translate(-50%, -50%);
  width: 22px; 
  height: 22px; 
  fill: currentColor; 
  transition: 0.4s;
}

.themeSwitch:checked + .slider .icon {
  transform: translate(14px, -50%);
}

// Dark Mode
body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}
