.formST-Header-logo {
  width: 35px !important;
  height: 35px !important;
  margin: 0px 10px !important;
}

.formST-Header {
  font-family: Roboto !important;
  font-size: 28px !important;
  font-weight: 400 !important;
  line-height: 32.81px !important;
  margin-bottom: 0px !important;
  text-align: left !important;
  color: var(--glob--text-color) !important;
}

.formST-stepsbar {
  height: 48px !important;
  display: flex !important;
  padding-right: 30px !important;
  align-items: center !important;
  font-family: Roboto !important;
  justify-content: space-between !important;
  border: 1px solid var(--glob--card-border-color)  !important;

  .title {
    font-family: Roboto !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 18.75px !important;
    text-align: left !important;
    color: var(--glob--text-color) !important;
  }

  .arrow {
    margin: 0px 8px !important;
    cursor: pointer !important;
  }

  span {
    font-family: Roboto !important;
    color: var(--glob--text-color) !important;
  }

  svg {
    height: 20px !important;
    width: 21px !important;
    margin: 0px 5px !important;
  }
}

.formST-Footer {
  margin: 0px !important;
  width: 100% !important;
  display: flex !important;
  height: 70px  !important;
  padding: 0px 19px !important;
  position: relative !important;
  font-family: Roboto !important;
  align-items: center !important;
  justify-content: space-between !important;

  .powered-by{
    font-family: Roboto !important;
    font-size: 13px !important;
    display: flex !important;
    align-content: center !important;
    align-items: center !important;
    position: absolute !important;
    top: -42px !important;
    color: var(--glob--text-color-secondary) !important;
    >div{
      background-image: var(--header__globiance--logo-crop) !important;
      background-repeat: no-repeat !important;
      background-position: left center !important;
      background-size: 125px auto !important;
      margin-left: 5px !important;
      width: 130px !important;
      height: 30px !important;
    }
  }

  .spinner-border {
    margin-left: 15px !important;
    height: 20px  !important;
    width: 20px  !important;
    color: #FFFFFF  !important;
  }

  h3 {
    //styleName: Header !important;
    font-family: Roboto !important;
    font-size: 28px !important;
    font-weight: 400 !important;
    line-height: 32.81px !important;
    text-align: left !important;
    color: var(--glob--text-color) !important;
    margin: 0px !important;
  }

  button {
    font-size: 26px !important;
    height: 48px !important;
    border: none !important;
    width: 156px !important;
    color: #FFF !important;
    border-radius: 7px !important;
    background-color: var(--glob--primary--color) !important;
    font-family: Roboto !important;

    &:disabled {
      opacity: 0.5 !important;
    }
  }

  .pad-35{
    padding-left: 35px  !important;
  }
}

.formST-dynamicInput {
  display: flex !important;
  font-family: Roboto !important;
  margin-bottom: 10px !important;
  flex-direction: column !important;
  align-items: flex-start !important;

  label {
    display: flex;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    font-family: Roboto;
    align-items: center;
    margin-bottom: 1rem;
    line-height: 21.09px;
    color: var(--glob--text-color-secondary);
    span {
      color: red !important;
      margin-left: 5px;
    }
  }

  .dollar-sign {
    top: 50% !important;
    left: 10px !important;
    position: absolute !important;
    font-family: Roboto !important;
    transform: translateY(-50%) !important;
    color: var(--glob--text-color) !important;
  }

  .description {
    width: 100% !important;
    display: flex !important;
    font-size: 11px !important;
    padding: 3px 5px !important;
    font-family: Roboto !important;
    justify-content: flex-end !important;
    color: var(--glob--text-color-secondary) !important;
  }

  .form-radio {
    display: flex !important;
    font-family: Roboto !important;

    label {
      margin: 0px !important;
    }
  }

  .form-input,
  .form-select,
  .form-textarea {
    gap: 5px !important;
    width: 100% !important;
    height: 43px !important;
    border-radius: 6px !important;
    font-family: Roboto !important;
    padding: 0px 10px 0px 25px !important;
    color: var(--glob--text-color) !important;
    background-color: var(--glob--card-bg-color) !important;
    border: 1px solid var(--glob--card-border-color)  !important;

    &:focus-visible {
      outline: none !important;
    }
  }

  .form-textarea {
    height: 50px !important;
    font-family: Roboto !important;
    padding: 5px 10px 5px 18px !important;
  }

  // .form-input,
  // .form-select {
  //   height: 32px !important;
  // }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  input[type="number"] {
    -moz-appearance: textfield !important;
  }

  input:disabled {
    cursor: not-allowed !important;
  }

  .qty-selector-img-wrapper {
    gap: 5px !important;
    width: 100% !important;
    display: flex !important;
    font-family: Roboto !important;
    align-items: center !important;


    .qty-selector-img-box {
      height: 31px !important;
      width: 41px !important;
      border-radius: var(--appBorderRadius) !important;

      img {
        height: 100% !important;
        width: 100% !important;
        min-width: 100% !important;
        object-fit: cover !important;
        font-size: 2px !important;
        border-radius: var(--appBorderRadius) !important;
      }
    }
  }

  .qty-selector-wrapper {
    gap: 5px !important;
    width: 100% !important;
    height: 43px !important;
    display: flex !important;
    border-radius: 6px !important;
    padding-right: 0px !important;
    align-items: center !important;
    font-family: Roboto !important;
    padding: 0px 10px 0px 10px !important;
    justify-content: space-between !important;
    color: var(--glob--text-color) !important;
    background-color: var(--glob--card-bg-color) !important;
    border: 1px solid var(--glob--card-border-color)  !important;

    &.show-image {
      .qty-selector-img-box {
        height: 31px !important;
        width: 40px !important;
        border-top-left-radius: var(--appBorderRadius) !important;
        border-bottom-left-radius: var(--appBorderRadius) !important;
      }
    }

    .qty-selector-input-block {
      display: flex !important;
      align-items: center !important;

      input {
        width: 30px !important;
        height: 31px !important;
        border: none !important;
        background: transparent !important;
        font-weight: bold !important;
        text-align: center !important;
      }

      button {
        height: 28px !important;
        width: 30px !important;
        padding: 5px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        border: none !important;
        font-weight: bold !important;
        border-color: #0000000f !important;
        background-color: var(--glob--card-bg-color) !important;
        color: var(--glob--text-color) !important;

        &:first-child {
          border-style: solid !important;
          border-width: 0px 1px 0px 1px !important;
        }

        &:last-child {
          border-style: solid !important;
          border-width: 0px 0px 0px 1px !important;
          border-top-right-radius: 6px !important;
          border-bottom-right-radius: 6px !important;
        }
      }
    }
  }
}

.formST-feildsarea {
  padding: 20px !important;
  font-family: Roboto !important;
  padding-bottom: 50px !important;
}
