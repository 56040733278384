:root {}

:root[data-theme="light"] {
    --glob--bg-color: #F9F9F9;
    --glob--text-color: #535353;
    --glob--text-color-secondary: #AAACB9;
    --glob--card-bg-color: #FFFFFF;
    --glob--card-border-color: #00000012;
    --theme-toggle-circle: #FFFFFF;
    --theme-toggle-bg: #D9D9D9;

    #root, body {
        background-color: #F9F9F9;
    }

    &:root[data-color="black"] {}
}

:root[data-theme="dark"] {
    --glob--bg-color: #25262A;
    --glob--text-color: #FFFFFF;
    --glob--text-color-secondary: #AAACB9;
    --glob--card-bg-color: #181A20;
    --glob--card-border-color: #FFFFFF12;
    --theme-toggle-circle: #343A40;
    --theme-toggle-bg: #181A20;

    #root, body {
        background-color: #25262A;
    }

    &:root[data-color="pink"] {}

    &:root[data-color="mint"] {}

    &:root[data-color="black"] {
        --glob--primary--color: #ffd455d9;
        --glob--selected-card-bg-color: rgba(255, 212, 85, 0.25);
        --glob--progressbar--color: rgba(255, 212, 85, 0.85);
    }
}

:root[data-color="pink"] {
    --glob--primary--color: #FE036A;
    --glob--selected-card-bg-color: #FFC0D7;
    --glob--progressbar--color: #FF65A7;
}