
@import "colors";

$primary: var(--thColor);
$primary-dark: var(--thColor);
$secondary: #282828;
$text-color: #0a0a0a;
$black: #000000;

$primary-font-family: "Futura Hv BT";
$secondary-font-family: "FuturaDemiC";
$futura-bold: "futura-bold";
$futura-md-bt: "Futura Md BT";
$body-font-size: 16px;
$body-font-weight: 500;
$h1-font-size: 36px;
$h2-font-size: 34px;
$h3-font-size: 30px;
$h4-font-size: 28px;
$h5-font-size: 24px;
$h6-font-size: 22px;

